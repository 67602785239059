import React from "react";

interface ButtonSocialMediaProps {
  socialMediaIcon: React.ReactNode;
  bgColor: string;
  onCLick?: () => void;
}

const ButtonSocialMedia = ({
  socialMediaIcon,
  bgColor,
  onCLick,
}: ButtonSocialMediaProps): JSX.Element => {
  return (
    <div
      onClick={onCLick}
      className={`${bgColor} w-[39px] h-[39px] rounded-[40px] flex justify-center items-center cursor-pointer aspect-square`}
    >
      {socialMediaIcon}
    </div>
  );
};

export default ButtonSocialMedia;
