import React, {useState} from 'react';
import { MenuButton } from './MenuButton';
import { AtLogo } from './AtLogo';
import useNavigation, { HeaderTheme } from "./useNavigation";
import MainMenu from "../MainMenu";
import useWindowDimensions from "../../../utils/useWindowsDimensions";
import './Animation.css';
import { LogoAnimationContext } from "./useLogoAnimation";
import { useBurgerMenuAnimation } from "./useBurgerAnimation";
import { Link } from 'gatsby';

type Props = {
  theme: HeaderTheme,
  mobileTheme?: HeaderTheme,
  tabletTheme?: HeaderTheme,
  sticky?: boolean,
  opacity?:boolean,
  isHome?: boolean
}

type TextColorGetter = (theme: HeaderTheme) => string

const getLogoTextColorFromTheme: TextColorGetter = (theme) => {
  return theme === HeaderTheme.Dark || theme === HeaderTheme.DarkLight ? "text-white" : "text-deep-blue";
}

const getIconTextColorFromTheme: TextColorGetter = (theme) => {
  return theme === HeaderTheme.Light
    ? "text-deep-blue"
    : theme === HeaderTheme.DarkLight
    ? "text-deep-blue"
    : "text-white";
}

const getColor = (className: string) => className === "text-deep-blue" ? "#24135F" : "#FFFFFF";

export function Navbar({ theme = HeaderTheme.Light, tabletTheme = HeaderTheme.Light, mobileTheme = HeaderTheme.Light, sticky = false, opacity = false, isHome = false }: Props) {
  const { width } = useWindowDimensions();
  const navTheme : HeaderTheme = (theme.endsWith("-alt") ? theme.slice(0,-4) : theme) as HeaderTheme;
  const navThemeMobile: HeaderTheme = (mobileTheme?.endsWith("-alt") ? mobileTheme?.slice(0, -4) : mobileTheme) as HeaderTheme;
  const navThemeTablet: HeaderTheme = (tabletTheme?.endsWith("-alt") ? tabletTheme?.slice(0, -4) : tabletTheme) as HeaderTheme;
  const { links } = useNavigation();
  const [navbarOpen, setNavbarOpen] = useState(false);
  const iconFill = theme.endsWith("-alt") ? '[&_path]:fill-current' : '';  
  const stickyStyle = sticky
    ? `fixed ${opacity ? "bg-white/90" : "bg-white"} w-full md:max-w-[var(--max-website-width)] top-0 overflow-hidden`
    : "";

  const { show: showMenuAnim } = useBurgerMenuAnimation();

  const getResponsiveColor = (colorFn: Function) => {
    return width <= 768 ? getColor(colorFn(navThemeMobile)) : width <= 1024 ? getColor(colorFn(navThemeTablet)) : getColor(colorFn(navTheme));
  }

  const handleToggle = () => {
    setNavbarOpen(!navbarOpen);
  };

  return (
    <nav
      className={`${stickyStyle} h-[62px] md:h-[114px] flex justify-between items-center p-4 md:p-8`}
    >
      <LogoAnimationContext.Consumer>
        {({show}) => (
          <div
            className={`flex-1 ${
              isHome ? (show ? 'fade-in' : 'fade-out') : ''
            }`}
            style={{color: getResponsiveColor(getLogoTextColorFromTheme)}}
          >
            <Link to='/' className='cursor-pointer'>
              <span className={iconFill}>
                <AtLogo />
              </span>
            </Link>
          </div>
        )}
      </LogoAnimationContext.Consumer>
      <div className="w-[50px] h-[30px] md:h-[50px] relative flex flex-0 flex-row items-center justify-center self-stretch overflow-visible">
        <div
          className={`relative overflow-visible z-10`}
          onClick={handleToggle}
          style={{ color: getResponsiveColor(getIconTextColorFromTheme) }}
        >
          <div className="flex items-center justify-center">
            <MenuButton />
          </div>
        </div>
        {/* Pulsating circle animation */}
        {isHome && (
          <div
            className={`z-0 absolute -inset-[25%] flex items-center justify-center pointer-events-none circle-container ${showMenuAnim && 'active'}`}
          >
            <div
              className="aspect-square h-[75%] bg-white rounded-full pulse"
            />
          </div>
        )}
      </div>
      <MainMenu links={links} onClose={handleToggle} open={navbarOpen} />
    </nav>
  );
}
