import React, { Fragment } from "react";
import { Footer } from "../components/base/BaseFooter/Footer";
import { Navbar } from "../components/base/Navbar/Navbar";
import useNavigation, {
  HeaderTheme,
} from "../components/base/Navbar/useNavigation";
import MaxWidthWrapper from "../components/MaxWidthWrapper/MaxWidthWrapper";
import NavbarAnimationsProvider from "../components/base/Navbar/NavbarAnimationsProvider";
import ContextValueProvider from "../utils/useContextValue";
import CookieModal from "../components/CookieModal/CookieModal";
import Credits from "../components/Credits";

interface Props {
  children: JSX.Element;
}

export default function Layout({ children }: Props) {
  // Conditionally don't show footer
  // @ts-ignore
  const path = children?.props.location.pathname || "/";
  let isHome = path === "/";
  const { links, subLinks } = useNavigation();
  const currentLink =
    links.find((link) => link.path === path) ||
    subLinks.find((link) => link.path === path);
  const headerTheme = currentLink?.headerTheme || HeaderTheme.Light;
  const mobileHeaderTheme =
    currentLink?.mobileHeaderTheme ||
    currentLink?.headerTheme ||
    HeaderTheme.Light;
  const tabletHeaderTheme =
    currentLink?.tabletHeaderTheme ||
    currentLink?.headerTheme ||
    HeaderTheme.Light;
  const isSticky = currentLink?.sticky || false;
  const hasOpacity = currentLink?.opacity || false;

  return (
    <>
      <NavbarAnimationsProvider>
        <ContextValueProvider>
          <Fragment>
            <Credits />
            <MaxWidthWrapper
              limit
              className="bg-at-site bg-[length:200%_200%] animate-slow-motion"
            >
              <div className="absolute w-full z-40">
                <MaxWidthWrapper limit>
                  <Navbar
                    theme={headerTheme}
                    tabletTheme={tabletHeaderTheme}
                    mobileTheme={mobileHeaderTheme}
                    sticky={isSticky}
                    opacity={hasOpacity}
                    isHome={isHome}
                  />
                </MaxWidthWrapper>
              </div>
              <div className="bg-[#eee]">
                {children}
              <Footer />
              </div>
            </MaxWidthWrapper>
          </Fragment>
        </ContextValueProvider>
      </NavbarAnimationsProvider>
      <CookieModal />
    </>
  );
}
