import React, { useState, useEffect, useRef } from "react";
import Icon from "../../../assets/icons/CloseWhite.svg";
import Player from "../../Player";
import LottieAnimation from "../../../assets/lotties/Thanks_mail_envelope.json";
import Button from "../../Button/Button";

const textInputStyle =
  "w-full focus:outline-none body border-[0.25rem] border-[#CCCCCC] p-2 py-3 rounded-[0.6rem]";

function MailModal({
  showContainer,
  onClose,
}: {
  showContainer: boolean;
  onClose: () => void;
}) {
  let name: HTMLInputElement | null,
    email: HTMLInputElement | null,
    message: HTMLTextAreaElement | null;

  const [show, setShow] = useState(false);
  const [sent, setSent] = useState(false);
  const [showPlayer, setShowPlayer] = useState(true);

  useEffect(() => {
    if (showContainer) setShow(true);
  }, [showContainer]);

  function handleHide() {
    setShow(false);
    setTimeout(() => {
      onClose();
      setSent(false);
      setShowPlayer(true);
    }, 1000);
  }

  async function handleSend() {
    if (name?.value && email?.value && message?.value) {
      try {
        await fetch(process.env.GATSBY_CONTACT_US_API ?? "", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            name: name.value,
            email: email.value,
            message: message.value,
          }),
        });
      } catch (error) {
        console.log(error);
      }
      setSent(true);
      setTimeout(() => {
        setShowPlayer(false);
        handleHide();
      }, 2500);
    }
  }

  return (
    <>
      {showContainer && (
        <div
          className={`z-0 fixed w-screen h-screen inset-0 overflow-hidden bg-deep-blue/30 transition-opacity ease-in duration-500 ${
            show ? "opacity-100" : "opacity-0"
          }`}
        >
          <div
            className={`z-10 fixed w-screen h-screen flex justify-center inset-0 overflow-hidden transition-transform duration-1000 ease-in-out ${
              show
                ? "translate-y-[0%] rotate-0"
                : "translate-y-[100%] rotate-[10deg]"
            }`}
          >
            <div
              className={`w-full max-w-[400px] md:w-full md:max-w-[800px] top-[10%] absolute bg-white p-4 rounded-xl pointer-events-auto`}
            >
              <div className="w-full">
                <Icon
                  onClick={handleHide}
                  className="absolute right-0 mr-3 bg-[#888888] p-2 rounded-full cursor-pointer"
                />
                <h3 className="my-2 text-vibrant-green text-center">
                  {sent ? "Thanks!" : "Reach Out To Us"}
                </h3>
                <div
                  className="h-1 w-full rounded-[1rem]"
                  style={{ backgroundColor: "gray" }}
                ></div>
                {!sent && (
                  <>
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        handleSend();
                      }}
                    >
                      <div className="lex flex-col md:flex-row">
                        <div className={`grow my-2 mt-4 md:my-6 mx-1`}>
                          <p className="ml-1 mb-1 caption text-deep-blue">
                            FULL NAME
                          </p>
                          <input
                            ref={(node) => {
                              name = node;
                            }}
                            className={`${textInputStyle}`}
                            type="text"
                            placeholder="First Last"
                            required
                          ></input>
                        </div>
                        <div className={`grow my-2 md:my-6 mx-1`}>
                          <p className="ml-1 mb-1 caption text-deep-blue">
                            EMAIL
                          </p>
                          <input
                            ref={(node) => {
                              email = node;
                            }}
                            className={`${textInputStyle}`}
                            type="email"
                            placeholder="name@website.com"
                            required
                          ></input>
                        </div>
                      </div>
                      <p className="mt-2 mb-1 md:mt-0 ml-1 caption text-deep-blue">
                        MESSAGE
                      </p>
                      <textarea
                        ref={(node) => {
                          message = node;
                        }}
                        className={`${textInputStyle} resize-none`}
                        rows={5}
                        placeholder="Tell us something..."
                        required
                      ></textarea>
                      <Button
                        className={"m-auto mb-3 mt-5"}
                        title={"Send Your Message"}
                        submit={true}
                      />
                    </form>
                  </>
                )}
                {sent && (
                  <>
                    <div className="flex flex-col md:flex-col items-center">
                      <div
                        className={`mt-[-18px] md:mt-[-22px] ${
                          !showPlayer && "opacity-0"
                        }`}
                      >
                        <Player start={true} src={LottieAnimation} />
                      </div>
                      <p className="text-deep-blue subtitle text-center md:max-w-[473px] sm:mt-[-54px]">
                        Thanks for reaching out! We'll get back to you as soon
                        as we can.
                      </p>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default MailModal;
