import React from "react";

interface ICreditsTitle {
  children: React.ReactNode;
  className?: string;
}

const CreditsTitle = ({ children, className }: ICreditsTitle) => {
  return (
    <div
      className={`subtitle-3-light text-xs md:subtitle-2-light w-full ${className}`}
    >
      {children}
    </div>
  );
};

export default CreditsTitle;
