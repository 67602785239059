import {useContextValue} from "./useContextValue";
import {useCallback, useEffect} from "react";

export default function useValue<T>( key: string, defaultValue: T ) {
  const { value, setValue: setStore } = useContextValue();

  const setValue = useCallback(( value: T ) => {
    setStore( (old: Record<string, any>) => ({ ...old, [key]: value }) );
  }, [key]);

  useEffect(() => {
    if (!value || undefined === value?.[key]) {
      setValue(defaultValue);
    }
  }, [key, defaultValue, setValue]);

  return {
    value: value?.[key] as T,
    setValue
  }
}
