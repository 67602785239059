import React, { useCallback, useEffect } from "react";
import { createContext, useContext, useState } from "react";

type AnimationContextType = {
  show: boolean;
  fadeIn: () => void;
  fadeOut: () => void;
};

export const BurgerMenuAnimationContext = createContext<AnimationContextType>(
  {} as AnimationContextType
);
export const useBurgerMenuAnimation = () =>
  useContext(BurgerMenuAnimationContext);

export default function BurgerMenuAnimationProvider({
  children,
}: {
  children: JSX.Element;
}) {
  const [show, setShow] = useState(true);

  const fadeIn = useCallback(() => setShow(true), []);

  const fadeOut = useCallback(() => setShow(false), []);

  return (
    <BurgerMenuAnimationContext.Provider value={{ show, fadeIn, fadeOut }}>
      {children}
    </BurgerMenuAnimationContext.Provider>
  );
}
