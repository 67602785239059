import React from 'react';
import { Link } from "../../Link/Link";
import { Divider } from "../../Divider/Divider";

interface FooterCopyrightProps{
    onOpenContactUs: () => void,
}

export function FooterCopyright({onOpenContactUs}: FooterCopyrightProps) {
    const atText = "©️ 2023 AgileThought, Inc.";
    const privacyPolicyText = "Privacy Policy";
    const privacyPolicyUrl = "/privacy-policy";
    const termsOfUseText = "Terms of Use";
    const termsOfUseUrl = "/terms-of-use";
    const mediaInquiriesText = "Media Inquiries";
    return (
        <>
            <div className="hidden md:flex flex-row flex-wrap md:flex-row justify-center md:justify-start pt-4 pb-4">
                <div className="white-link body">{atText}</div>
                <Divider text=" " />
                <Link title={privacyPolicyText} url={privacyPolicyUrl} />
                <Divider text=" " />
                <Divider text="|" />
                <Link title={termsOfUseText} url={termsOfUseUrl} />
                <Divider text=" " />
                <Divider text="|" />
                <a onClick={onOpenContactUs} className='body white-link cursor-pointer'>{mediaInquiriesText}</a>
            </div>
            <div className="sm:visible md:hidden flex flex-col justify-self-center justify-center justify-items-center items-center">
                <div className="white-link font-subtitleTwo">©️ 2023 AgileThought, Inc.</div>
                <div className="flex flex-row">
                    <Link title={privacyPolicyText} url={privacyPolicyUrl} />
                    <Divider text=" " />
                    <Divider text="|" />
                    <Link title={termsOfUseText} url={termsOfUseUrl} />
                </div>
                <div className="flex flex-row">
                    <a onClick={onOpenContactUs} className='body white-link'>{mediaInquiriesText}</a>
                </div>
            </div>
        </>
    )
};