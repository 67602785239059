import React, { Children } from "react";

interface ICreditsName {
  children: React.ReactNode;
  className?: string;
}

const CreditsName = ({ children, className }: ICreditsName) => {
  return (
    <div
      className={`subtitle-3-bold text-xs md:subtitle-2-bold uppercase w-full ${className}`}
    >
      {children}
    </div>
  );
};

export default CreditsName;
