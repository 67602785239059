import React, {useMemo, useRef} from "react";
import { Player as LottiePlayer, IPlayerProps } from "@lottiefiles/react-lottie-player";

export interface PlayerProps extends IPlayerProps {
  interval?: number;
  delay?: number;
  stopAt?: number;
  className?: string;
  start?: boolean;
}

const Player = ({
  delay = 0,
  interval,
  className,
  start = true,
  ...playerProps
}: PlayerProps): JSX.Element => {
  const player: React.LegacyRef<LottiePlayer> = useRef(null);

  useMemo(() => {
    let loop: ReturnType<typeof setInterval>;
    const timer = setTimeout(() => {
      if (start) player?.current?.play();
      if (interval) {
        loop = setInterval(() => {
          player?.current?.play();
        }, interval);
      }
    }, delay);

    return () => {
      clearTimeout(timer);
      loop && clearInterval(loop);
    };
  }, [start]);

  return <LottiePlayer ref={player} {...playerProps} className={className} />;
};

export default Player;
