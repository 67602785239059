import React from 'react';
export function AtLogo() {
    return (
        <svg width="225" height="39" viewBox="0 0 225 39" fill="none" xmlns="http://www.w3.org/2000/svg" style={{maxWidth: "100%"}}>
            <g clipPath="url(#clip0_137_6401)">
                <path d="M19.3036 2.0999L13.6386 13.0721C13.2838 13.7608 13.7268 14.6197 14.4386 14.6197H30.6733C31.383 14.6197 31.8282 13.7587 31.4733 13.0721L25.8084 2.0999C24.3638 -0.699315 20.7502 -0.699315 19.3057 2.0999H19.3036Z" fill="#0DB04B" />
                <path d="M15.8566 19.5042H10.5822C9.51766 19.5042 8.53291 20.09 8.00169 21.0434L0.386132 34.6678C-0.963965 37.0827 1.46957 39.8819 3.9283 38.7354C7.8946 36.8874 12.7428 35.0878 17.2718 34.6552C18.3678 34.5502 19.2623 33.4939 19.2623 32.3579C19.2623 29.9051 19.2623 25.9048 19.2623 23.0194C19.2623 21.077 17.7379 19.5084 15.8587 19.5084L15.8566 19.5042Z" fill="#0DB04B" />
                <path d="M29.2553 19.5042H34.5297C35.5942 19.5042 36.579 20.09 37.1102 21.0434L44.7258 34.6657C46.0759 37.0806 43.6423 39.8798 41.1836 38.7333C37.2173 36.8853 32.3691 35.0857 27.8401 34.6531C26.7441 34.5481 25.8496 33.4918 25.8496 32.3558C25.8496 29.903 25.8496 25.9027 25.8496 23.0173C25.8496 21.0749 27.374 19.5063 29.2532 19.5063L29.2553 19.5042Z" fill="#0DB04B" />
                <path d="M48.9788 29.2898L56.1072 11.8793C56.5923 10.6949 57.745 9.92004 59.0237 9.92004C60.3066 9.92004 61.4635 10.6991 61.9444 11.8877L68.9972 29.2877H64.8356L63.5002 25.9363H54.4716L53.1362 29.2877H48.9746L48.9788 29.2898ZM55.8889 22.4063H62.0913L59.6641 15.6361C59.4415 15.0145 58.5639 15.0145 58.3392 15.6361L55.891 22.4084L55.8889 22.4063Z" fill="currentColor" />
                <path d="M79.3027 25.2307C84.1698 25.2307 85.7677 27.1942 85.7677 29.7855C85.7677 32.6918 83.4643 34.8379 78.2571 34.8379C73.0499 34.8379 70.8242 32.6918 70.8242 29.7855C70.8242 28.2946 71.557 27.2467 72.1848 26.6965C71.5822 26.1211 71.2169 25.3882 71.2169 24.4202C71.2169 23.0069 72.1071 22.0913 72.8126 21.6189C72.1848 20.8335 71.8447 19.9179 71.8447 18.8175C71.8447 16.096 73.8079 14.2376 78.2298 14.2376C78.9626 14.2376 79.6429 14.2901 80.2455 14.3951C80.3253 12.3014 82.4187 11.3334 84.8249 11.3334V14.3951C84.0669 14.3951 83.2291 14.5526 82.7315 15.2582C84.0144 16.0687 84.6422 17.3266 84.6422 18.8175C84.6422 21.5916 82.6265 23.4248 78.2571 23.4248C77.054 23.4248 75.9286 23.2673 75.0908 23.0069C74.8808 23.2694 74.7506 23.5571 74.7506 23.9498C74.7506 24.7352 75.3259 25.2328 77.1065 25.2328H79.3048L79.3027 25.2307ZM82.2339 29.7582C82.2339 28.6578 81.4486 28.1875 79.1453 28.1875H77.0267C76.1889 28.1875 75.3784 28.135 74.8031 27.9523C74.4105 28.5276 74.3328 29.0778 74.3328 29.7582C74.3328 31.014 75.4057 32.0618 78.2592 32.0618C81.1127 32.0618 82.2381 31.014 82.2381 29.7582H82.2339ZM75.4813 18.8175C75.4813 19.8906 76.2393 20.7012 78.255 20.7012C80.2707 20.7012 81.0035 19.8906 81.0035 18.8175C81.0035 17.7445 80.3232 16.9591 78.255 16.9591C76.1868 16.9591 75.4813 17.7445 75.4813 18.8175Z" fill="currentColor" />
                <path d="M94.5117 25.5457V10.4955L98.3058 9.6051V24.7603C98.3058 25.8859 98.751 26.1988 99.4838 26.1988C99.9814 26.1988 100.374 26.0161 100.949 25.5184V28.5549C100.427 29.1576 99.2486 29.6028 98.1757 29.6028C96.265 29.6028 94.5117 28.452 94.5117 25.5457Z" fill="currentColor" />
                <path d="M102.274 21.9338C102.274 17.4063 104.787 14.2396 109.654 14.2396C114.521 14.2396 116.719 17.3538 116.719 21.9611C116.719 21.9674 116.719 21.9716 116.719 21.9779C116.719 22.7948 116.048 23.452 115.231 23.452H106.173C106.488 25.5982 108.241 26.5137 110.492 26.5137C112.062 26.5137 113.396 26.2261 114.966 25.4659L116.117 28.1874C114.469 29.1555 112.663 29.6007 110.149 29.6007C104.862 29.6007 102.271 26.5116 102.271 21.9317L102.274 21.9338ZM113.003 20.8859C113.003 18.6348 111.878 17.3265 109.654 17.3265C107.43 17.3265 106.2 18.6873 106.2 20.8859H113.005H113.003Z" fill="currentColor" />
                <path d="M87.4961 12.8264C87.4961 11.8331 88.3024 11.0267 89.2955 11.0267H90.1354C91.1285 11.0267 91.9348 11.8331 91.9348 12.8264C91.9348 13.8196 91.1285 14.626 90.1354 14.626H89.2955C88.3024 14.626 87.4961 13.8196 87.4961 12.8264ZM87.8425 29.2878L87.9097 15.9784H91.7039L91.6367 29.2878H87.8425Z" fill="currentColor" />
                <path d="M121.18 13.5949H116.812V10.0229H132.237V13.5949H127.869C127.145 13.5949 126.555 14.1724 126.555 14.8843V29.313H122.492V14.8843C122.492 14.1724 121.904 13.5949 121.178 13.5949H121.18Z" fill="currentColor" />
                <path d="M134.33 29.313V10.5963L138.082 9.71008V16.8772C138.916 15.1825 140.428 14.3236 142.618 14.3236C145.771 14.3236 147.987 16.3837 147.987 20.6066V29.313H144.182V20.6066C144.182 18.6768 143.193 17.7129 141.419 17.7129C139.306 17.7129 138.108 19.017 138.108 21.5453V29.313H134.328H134.33Z" fill="currentColor" />
                <path d="M149.801 21.9883C149.801 17.2971 152.406 14.3257 157.645 14.3257C162.884 14.3257 165.49 17.2971 165.49 21.9883C165.49 26.6796 162.857 29.6258 157.645 29.6258C152.434 29.6258 149.801 26.6544 149.801 21.9883ZM161.53 21.9883C161.53 19.1723 160.383 17.6877 157.647 17.6877C154.911 17.6877 153.765 19.1723 153.765 21.9883C153.765 24.8044 154.911 26.2638 157.647 26.2638C160.383 26.2638 161.53 24.7771 161.53 21.9883Z" fill="currentColor" />
                <path d="M167.469 23.1601V14.6365H171.248V23.1349C171.248 25.2726 172.344 26.2638 174.114 26.2638C175.884 26.2638 177.033 25.2726 177.033 23.1349V14.6365H180.812V23.1601C180.812 27.1479 178.284 29.6237 174.114 29.6237C169.944 29.6237 167.469 27.1479 167.469 23.1601Z" fill="currentColor" />
                <path d="M191.154 25.2727C196.002 25.2727 197.592 27.2277 197.592 29.8085C197.592 32.7023 195.297 34.84 190.11 34.84C184.924 34.84 182.709 32.7023 182.709 29.8085C182.709 28.3218 183.44 27.2802 184.063 26.7321C183.463 26.1589 183.1 25.4281 183.1 24.4642C183.1 23.0573 183.986 22.1438 184.689 21.6755C184.063 20.8943 183.725 19.9808 183.725 18.8868C183.725 16.1758 185.68 14.3257 190.085 14.3257C190.816 14.3257 191.492 14.3782 192.092 14.4811C192.17 12.3959 194.255 11.432 196.653 11.432V14.4811C195.897 14.4811 195.064 14.6386 194.568 15.3421C195.845 16.1506 196.47 17.4021 196.47 18.8868C196.47 21.6503 194.463 23.4751 190.11 23.4751C188.911 23.4751 187.79 23.3176 186.957 23.0573C186.749 23.3176 186.619 23.6053 186.619 23.9959C186.619 24.7771 187.192 25.2727 188.964 25.2727H191.154ZM194.072 29.7812C194.072 28.6872 193.291 28.2168 190.996 28.2168H188.884C188.051 28.2168 187.242 28.1643 186.669 27.9816C186.278 28.5549 186.201 29.103 186.201 29.7812C186.201 31.0328 187.269 32.0765 190.11 32.0765C192.951 32.0765 194.072 31.0328 194.072 29.7812ZM187.347 18.8847C187.347 19.9535 188.103 20.762 190.11 20.762C192.118 20.762 192.846 19.9535 192.846 18.8847C192.846 17.8158 192.168 17.0346 190.11 17.0346C188.053 17.0346 187.347 17.8158 187.347 18.8847Z" fill="currentColor" />
                <path d="M199.563 29.313V10.5963L203.315 9.71008V16.8772C204.148 15.1825 205.66 14.3236 207.85 14.3236C211.004 14.3236 213.219 16.3837 213.219 20.6066V29.313H209.414V20.6066C209.414 18.6768 208.425 17.7129 206.651 17.7129C204.539 17.7129 203.34 19.017 203.34 21.5453V29.313H199.561H199.563Z" fill="currentColor" />
                <path d="M221.496 17.7906H224.998V14.584H220.49V9.71008L216.704 10.5963V11.9528L216.698 14.6113H215.814H214.701V17.8074H215.827C216.259 17.8074 216.62 18.1077 216.706 18.5088V24.0211C216.706 28.4793 218.922 29.6259 221.632 29.6259C222.701 29.6259 223.978 29.3655 224.733 28.9476V25.8985C224.03 26.1841 223.379 26.3416 222.571 26.3416C221.267 26.3416 220.486 25.846 220.486 23.9959V18.7671C220.486 18.7167 220.49 18.6642 220.507 18.6159C220.626 18.2295 220.935 17.9271 221.328 17.8116C221.38 17.7969 221.437 17.7906 221.494 17.7906H221.496Z" fill="currentColor" />
            </g>
            <defs>
                <clipPath id="clip0_137_6401">
                    <rect width="225" height="39" fill="white" />
                </clipPath>
            </defs>
        </svg>);
}
