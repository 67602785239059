import React from "react";
import MaskedContainer, { Mask } from "../MaskedContainer/MaskedContainer";

export enum MaskType {
  DoubleBow,
  DoubleBowTop,
  TopBulge,
  BottomBow,
  Hexagon,
  HexagonBottom,
  None,
}

const BowTop = 'https://atwebsite.blob.core.windows.net/images/bow-top.svg';
const BowBottom = 'https://atwebsite.blob.core.windows.net/images/bow-bottom.svg';
const BulgeTop = "https://atwebsite.blob.core.windows.net/images/bow-top-convex.svg";

interface MaskConfig {
  top?: Mask | null,
  bottom?: Mask | null,
}

const MaskConfigurations: Record<MaskType, MaskConfig> = {
  [MaskType.DoubleBow]: {
    top: {image: BowTop},
    bottom: {image: BowBottom},
  },
  [MaskType.DoubleBowTop]: {
    top: {image: BulgeTop},
    bottom: {image: BowBottom},
  },
  [MaskType.TopBulge]: {
    top: {image: BulgeTop},
    bottom: {image: ''},
  },
  [MaskType.BottomBow]: {
    top: {image: ''},
    bottom: {image: BowBottom},
  },
  [MaskType.None]: {
    top: {image: ''},
    bottom: {image: ''},
  },
  [MaskType.Hexagon]: {},
  [MaskType.HexagonBottom]: {}
};

interface MaskShapeContainerProps {
  maskType?: MaskType;
  children?: JSX.Element | JSX.Element[] | React.ReactNode;
  className?: string;
  style?: React.CSSProperties,
}

const MaskShapeContainer = ({
  children,
  maskType = MaskType.None,
  className,
  style,
}: MaskShapeContainerProps): JSX.Element => (
  <MaskedContainer
    style={style}
    className={className}
    top={MaskConfigurations[maskType].top}
    bottom={MaskConfigurations[maskType].bottom}
  >
    <>{children}</>
  </MaskedContainer>
);

export default MaskShapeContainer;
