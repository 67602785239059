import React, { ReactElement } from "react";

export interface Mask {
    image: string,
    size?: string,
};

interface MaskContainerProps {
    top?: Mask | null,
    bottom?: Mask | null,
    className?: string,
    style?: React.CSSProperties,
    children?: JSX.Element | JSX.Element[],
}

const dfltMaskSize : string = '100% clamp(10px, 4vw, 50px)';

const bowTop : Mask = { 
    image :'https://atwebsite.blob.core.windows.net/images/bow-top.svg',
    size: dfltMaskSize };
const bowBottom : Mask = { 
    image :'https://atwebsite.blob.core.windows.net/images/bow-bottom.svg',
    size: dfltMaskSize };

const MaskedContainer: React.FC<MaskContainerProps> = ({ top = bowTop , bottom = bowBottom, className, style, children }): ReactElement => {
    const topImage : string = top?.image ? `url(${top.image})` : '';
    const bottomImage : string = bottom?.image ? `url(${bottom.image})` : '';
    const maskImage : string = [topImage, bottomImage,'linear-gradient(#fff,#fff)'].filter(n => n).join(',');

    const maskSize : string = [ 
        topImage ? top?.size || dfltMaskSize : '', bottomImage ? bottom?.size || dfltMaskSize : '',
        '100% 100%'].filter(n => n).join(',');

    const maskPosition = [ 
        topImage ? 'center top' : '', bottomImage ? 'center calc(100% + 0.5px)' : '',
        'center center'].filter(n => n).join(',');

    return (
        <div className={className}
            style={{ 
                WebkitMaskImage: maskImage, 
                maskImage: maskImage,
                WebkitMaskSize: maskSize,
                maskSize: maskSize,
                WebkitMaskComposite: 'destination-out',
                maskComposite: 'exclude',
                WebkitMaskOrigin: 'border-box',
                maskOrigin: 'border-box',
                WebkitMaskRepeat: 'no-repeat',
                maskRepeat: 'no-repeat',
                WebkitMaskPosition: maskPosition,
                maskPosition: maskPosition,
                ...style
            }} >
            {children}
        </div>
    );
}

export default MaskedContainer;
