import React from "react";
import { Link as GatsbyLink } from 'gatsby';


type Props = {
  title: string;
  children?: React.ReactNode;
  url: string;
};

export function LinksCard({ title, children, url}: Props) {
  return (   
    <div className="w-48 flex flex-col p-4">
      <GatsbyLink className="subtitle-2-light text-white"
        to={url}>
        {title}
      </GatsbyLink>
      {children}
    </div>


  );
};