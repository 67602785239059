import React from "react";
import { Link as GatsbyLink } from "gatsby";

type Props = {
  title: string;
  url: string;
  style?: string;
};

export function Link({ title, url, style }: Props) {
  return (
    <GatsbyLink
      className={`body ${style === undefined ? "white-link" : style}`}
      to={url}
    >
      {title}
    </GatsbyLink>
  );
}
