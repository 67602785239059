import React from 'react';
import { BottomPageMosaic } from "../../BottomPageMosaic/BottomPageMosaic";
import { FooterNavigation } from './FooterNavigation';

export function Footer() {
    return (
        <footer className="relative z-50 mt-0 md:mt-44 pb-96 md:pb-0 lg:mt-80 ">
            <FooterNavigation />
        </footer>
    );
}
