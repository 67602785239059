import React from "react";
import "./Button.css";

export enum ButtonIconPosition {
  Left = "left",
  Right = "right",
}

export enum ButtonTypes {
  Principal = "principal",
  Secondary = "secondary" ,
  Link = "link" ,
  Centered = "centered" ,
  Custom = "custom",
}

interface ButtonProps {
  title: string;
  icon?: React.ReactNode;
  iconPosition?: ButtonIconPosition;
  className?: string;
  buttonType?: ButtonTypes;
  customColors?: string;
  onClick?: () => void;
  submit?: boolean;
}

const Button = ({
  title,
  icon,
  iconPosition = ButtonIconPosition.Right,
  className = '',
  buttonType = ButtonTypes.Principal,
  customColors,
  onClick,
  submit
}: ButtonProps): JSX.Element => {
  const justifyContent = icon ? "justify-between" : "justify-center";
  let buttonStyles: string;
  let padding: string;
  let animationStyles: string = (buttonType === ButtonTypes.Principal || buttonType === ButtonTypes.Centered)
      ? `animated-button hover:scale-[1.05] active:translate-y-[4px]`
      : '';

  switch (buttonType) {
    case "principal":
      padding = icon ? "px-5" : "px-10";
      buttonStyles = `active:bg-bright-cyan-alt disabled:bg-disabled bg-vibrant-green rounded-xl disabled:text-[#888888] text-white text-xl font-ibmPlexSansSemiBold flex flex-row items-center py-3 ${padding}`;
      break;
    case "secondary":
      padding = icon ? "px-2" : "px-5";
      buttonStyles = `bg-white rounded-xl text-vibrant-green text-xs font-ibmPlexSansSemiBold flex flex-row items-center py-1 ${padding}`;
      break;
    case "link":
      padding = icon ? "px-5" : "px-10";
      buttonStyles = `bg-transparent text-vibrant-green text-xl font-ibmPlexSansSemiBold font-bold flex flex-row items-center py-1 ${padding}`;
      break;
    case "centered":
      padding = icon ? "px-5" : "px-10";
      buttonStyles = `active:bg-bright-cyan-alt disabled:bg-disabled bg-vibrant-green rounded-xl disabled:text-[#888888] text-white text-xl font-ibmPlexSansSemiBold flex-row items-center py-3 ${padding}`;
      break;
    case "custom":
      padding = icon ? "px-5" : "px-10";
      buttonStyles = `${customColors} rounded-xl button flex flex-row items-center py-3 ${padding}`;
      break;
    default:
      buttonStyles = "";
      break;
  }

  return (
    <button
      className={`${animationStyles} ${buttonStyles} ${justifyContent} ${className}`}
      onClick={onClick}
      type={submit ? "submit" : "button"}
    >
      {iconPosition === ButtonIconPosition.Left && icon}
      {title}
      {iconPosition === ButtonIconPosition.Right && icon}
    </button>
  );
};

export default Button;
