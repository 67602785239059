import React from "react";
import ShareOnSocialMedia, {
  SocialMedia,
} from "../ShareOnSocialMedia/ShareOnSocialMedia";

const SocialMediaLink: Record<string, string> = {
  Facebook: "https://www.facebook.com/AgileThought",
  Instagram: "https://www.instagram.com/agilethought/",
  Twitter: "https://twitter.com/AgileThought",
  Linkedin: "http://www.linkedin.com/company/agilethought",
  Youtube: "https://www.youtube.com/@AgileThought",
};

export function SocialMediaIcons() {
  const goToLink = (name: SocialMedia): void => {
    window.open(SocialMediaLink[name], "_blank");
  };
  const socialMedia: SocialMedia[] = [
    SocialMedia.Youtube,
    SocialMedia.Instagram,
    SocialMedia.Facebook,
    SocialMedia.Linkedin,
    SocialMedia.Twitter,
  ];
  return (
    <div className="flex justify-center md:justify-items-end gap-x-4">
      <ShareOnSocialMedia
        socialMedia={socialMedia}
        bgColor="bg-[#FFFFFF40]"
        iconColor="#FFFFFF"
        onClick={goToLink}
      />
    </div>
  );
}
