import React from "react";

type Props = {
    children?: React.ReactNode;
};

export function CardRow({ children }: Props) {
    return (
        <div className="flex flex-row flex-wrap justify-center xl:justify-evenly">
            {children}
        </div>
    );
}
