import { graphql, useStaticQuery } from "gatsby";

const footerDefaultInfo = [ 
   { 
      subtitle: 'Work',
      to: '/work',
      cards: [
         { subtitle: 'Retailer Study', to: '/case-study/large-retailer' },
         { subtitle: 'Riot Games Study', to: '/case-study/riotgames' },
         { subtitle: 'Healthcare Study', to: '/case-study/healthcare' },
         { subtitle: 'Santander Study', to: '/case-study/santander' },
         { subtitle: 'Financial Study', to: '/case-study/financial' },
      ]
   },
   { 
      subtitle: 'Services',
      to: '/services',
      cards: [
         { subtitle: "Agility", to:"/services/agility/" },
         { subtitle: "Design & Product", to:"/services/design-product" },
         { subtitle: "Data & Analytics", to:"/services/data" },
         { subtitle: "Applied AI", to:"/services/applied-ai" },
         { subtitle: "Gaming", to:"/services/gaming" },
         { subtitle: "Enterprise Solutions", to:"/services/enterprise" },
         { subtitle: "Front-End Engineering", to:"/services/frontend-engineering" },
         { subtitle: "Backend Engineering", to:"/services/backend-engineering" },
         { subtitle: "Cloud & Security", to:"/services/cloud-security" },
         { subtitle: "Quality", to:"/services/quality" }
      ],
   },
   { 
      subtitle: "Industries" ,
      to: "/industries",
      cards: [
         { subtitle: "Financial Services", to: "/industry/financial" },
         { subtitle: "Healthcare & Life Sciences", to: "/industry/healthcare" },
         { subtitle: "TMT", to: "/industry/media-entertainment" },
         { subtitle: "Professional Services", to: "/industry/professional-services" },
         { subtitle: "Products & Retail", to: "/industry/products" }
      ]
   }, {
      subtitle: "Insights",
      to: "/insights",
      cards: [
         { subtitle: "Blog Posts", to: "/insights-detail/?posts" },
         { subtitle: "Podcast", to: "/insights-detail/?podcasts" },
         { subtitle: "Events", to: "/insights-detail/?webinars" }
      ]
   }, {
      subtitle: "Careers",
      to: "/careers",
      cards: []
   }

];

const useFooterInfo = () => {
   const data = useStaticQuery(graphql`
      query {
         Payload {
            Pages(where: { slug: { equals: "website-footer" } }) {
               docs {
                  sections {
                     subtitle
                     to
                     cards {
                        subtitle
                        to
                     }
                  }
               }
            }
         }
      }
   `);
   
   return data.Payload?.Pages?.docs?.[0]?.sections || footerDefaultInfo;
}

export default useFooterInfo;
