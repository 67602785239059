import React, {useEffect, useState} from "react"
import Button, {ButtonTypes} from "../Button/Button"
import Player from "../Player"
import LottieAnimation from "../../assets/lotties/GDPR_cookie.json";
import { Link } from "gatsby";

const COOKIE_AGREE = "cookieAgree"

function CookieModal() {

    const [showModal, setShowModal] = useState(false)
    const [play, setPlay] = useState(false)
    const [showContainer, setShowContainer] = useState(false)

    useEffect(() => {
        const cookieAgree = localStorage.getItem(COOKIE_AGREE)
        if (cookieAgree === null) setShowContainer(true)
    }, [])

    useEffect(()=>{
        if (showContainer) {setShowModal(true)}
    },[showContainer])

    function handleAgree() {
        localStorage.setItem(COOKIE_AGREE, "true")
        setPlay(true)
        setTimeout(()=>{setShowModal(false)},1500)
        setTimeout(()=>{setShowContainer(false)},2500)
    }

    function handleDisagree() {
        localStorage.setItem(COOKIE_AGREE, "false")
        setShowModal(false)
        setTimeout(()=>{setShowContainer(false)},1000)
    }

    return (
    <>
        {
        showContainer && (
        <div className="z-[999] fixed w-[100vw] h-[100vh] bottom-0 overflow-hidden pointer-events-none">
            <div
                className={`transition-transform duration-1000 ease-in-out absolute left-[50%] bottom-[-500px] translate-x-[-50%] bg-white p-4 pt-24 sm:pt-4 sm:pl-[224px] flex rounded-xl pointer-events-auto ${showModal ? "translate-y-[-550px]" : ""}`}
            >
                <div className="absolute w-[200px] h-[190px] translate-x-[70px] translate-y-[-180px] sm:translate-x-[-210px] sm:translate-y-[-80px] scale-75 sm:scale-100">
                    <Player start={play} src={LottieAnimation}></Player>
                </div>
                <div className="w-[336px] sm:w-[427px]">
                    <h3 className="text-vibrant-green">Who wants a cookie?</h3>
                    <p className="sm:w-[80%]">Our site uses cookies, by clicking you agree to our <Link className="text-vibrant-green" to="/privacy-policy">Privacy Policy</Link></p>
                    <div className="flex flex-col sm:flex-row mt-2">
                        <Button
                            title={"I'll have a cookie"}
                            onClick={handleAgree}
                        />
                        <Button
                            title={"No thanks"}
                            buttonType={ButtonTypes.Custom}
                            className="mt-3 sm:mt-0 sm:ml-4 bg-[#CCCCCC] text-disabled"
                            onClick={handleDisagree}
                        />
                    </div>
                </div>
            </div>
        </div>
        )}
    </>
    )
}

export default CookieModal
