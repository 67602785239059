import React, { Fragment } from "react";
import CreditsTitle from "./CreditsTitle";
import CreditsName from "./CreditsName";

interface IRoleRow {
  role: string | Array<string>;
  people: string | Array<string>;
}

interface ICreditsSection {
  data: Array<IRoleRow>;
}

const CreditsSection = ({ data }: ICreditsSection) => {
  return (
    <div className="w-full grid grid-cols-2 p-4 items-center">
      {data.map((role, index) => (
        <Fragment key={`credits-title-${index}`}>
          <CreditsTitle
            className={`text-right pr-4 w-1/2`}
            key={`title-${index}`}
          >
            {typeof role.role === "string" ? role.role : role.role.join(", ")}
          </CreditsTitle>
          <CreditsName className={`pl-4 w-1/2`} key={`name-${index}`}>
            {typeof role.people === "string"
              ? role.people
              : role.people.join(" · ")}
          </CreditsName>
        </Fragment>
      ))}
    </div>
  );
};

export default CreditsSection;
