import React, { useState, useEffect, useRef } from "react";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import useSound from "use-sound";

import useCredits from './useCredits';
import CreditsModal from "./CreditsModal";

import creditsSong from "./../../assets/audio/credits_song.mp3";

export interface ICredits {
  people: {
    role: string[],
    people: string[]
  }[][],
  gratitude: {
    title: string,
    names: string[]
  }[]
}

type CreditsProps = { 
  closeCreditsModal: () => void, 
  creditsData: ICredits 
}

const CreditsWrapper = () => {
  const { 
    creditsData, 
    isCreditsModalActive, 
    closeCreditsModal 
  } = useCredits();

  return (
    isCreditsModalActive && creditsData ? (
      <Credits 
        closeCreditsModal={ closeCreditsModal } 
        creditsData={ creditsData }
      />
    ) : <></>
  )
}

const Credits = ({ closeCreditsModal, creditsData }: CreditsProps) => {
  const ANIMATION_DURATION = 60;
  const START_FADEOUT_SONG = 5;

  const creditsContainerRef = useRef<HTMLDivElement>(null);
  const creditsContentRef = useRef<HTMLDivElement>(null); 

  const [showCredits, setShowCredits] = useState<boolean>(false);
  const [creditsTween, setCreditsTween] = useState<GSAPTween>();
  const [pauseAnimation, setPauseAnimation] = useState<boolean>(false);
  
  const [play, { stop, pause, sound }] = useSound(creditsSong, { 
    volume: 0
  });

  useEffect(() => {
    if(sound) 
      setShowCredits(true);
  }, [sound]);

  useEffect(() => {
    if(showCredits) {
      creditsTween?.restart();
      play();
      sound?.fade(0, 1, 1000);
    } else {
      creditsTween?.pause();
      stop();
    }

    !showCredits && creditsTween?.eventCallback("onComplete", null);
  }, [showCredits]);

  useEffect(() => {
    if(!pauseAnimation) {
      creditsTween?.play();
      play();
    } else {
      creditsTween?.pause();
      pause();
    }
  }, [pauseAnimation]);

  useGSAP(
    () => {      
      setCreditsTween(
        gsap.fromTo(
          ".credits",
          { translateY: `${window.innerHeight}px`, ease: 'linear', height: `${creditsContentRef.current?.clientHeight}px` },
          { translateY: `-${creditsContentRef.current?.clientHeight}px`, ease: 'linear', duration: ANIMATION_DURATION }
        ).pause()
      );
    },
    { scope: creditsContainerRef, dependencies: [] }
  );

  creditsTween?.eventCallback("onComplete", () => {
    onCompleteEvent();
  });

  creditsTween?.eventCallback("onUpdate", () => {
    if(creditsTween.time() > (ANIMATION_DURATION - START_FADEOUT_SONG)) {
      creditsTween?.eventCallback("onUpdate", null);
      sound?.fade(1, 0, START_FADEOUT_SONG * 1000);
    }
  });

  const onCompleteEvent = () => {
    setShowCredits(false);
    setTimeout(() => {
      closeCreditsModal();
    }, 500);
  }

  return (
    <CreditsModal 
      showCredits={ showCredits }
      pauseAnimation={ pauseAnimation }
      creditsContainerRef={ creditsContainerRef }
      creditsContentRef={ creditsContentRef }
      onCompleteEvent={ onCompleteEvent }
      setPauseAnimation={ setPauseAnimation }
      creditsData={ creditsData }
    />
  );
};

export default CreditsWrapper;