import { useEffect, useState } from "react";
import useValue from "../../utils/useValue";

import creditsDataJson from './Credits.json';
import { ICredits } from ".";

const defaultCreditsModal = {
  id: 'credits',
  active: false
}

const useCredits = () => {
  const [creditsData, setCreditsData] = useState<ICredits | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [isCreditsModalActive, setIsCreditsModalActive] = useState(false);
  
  const { value: currentModal, setValue: setCurrentModal } = useValue('currentModal', defaultCreditsModal);

  // Get creditsData
  useEffect(() => {
    const people = creditsDataJson.people.map((item) => {
      return item.data.map((item) => { 
        return {
          role: item.roles,
          people: item.names
        }
      });
    });

    const gratitude = creditsDataJson.gratitude.map((item) => {
      return {
        title: item.title,
        names: item.names,
      }
    })

    try {
      setCreditsData({
        people,
        gratitude
      });
    } catch (error) {
      setError(error as Error);
    }
  }, []);

  // Validate if modal is active
  useEffect(() => {
    if(currentModal && creditsData) {
      if(currentModal.id === "credits" && currentModal.active === true) {
        setIsCreditsModalActive(true);
      }
    }
  }, [currentModal, creditsData]);

  const closeCreditsModal = () => {
    setIsCreditsModalActive(false);    
    setCurrentModal(defaultCreditsModal);
  } 

  return {
    isCreditsModalActive, 
    closeCreditsModal,
    creditsData, 
    error    
  }
}

export default useCredits;
