import * as React from "react";
export default function MaxWidthWrapper({children, limit = true, className = ''}: {
  children: JSX.Element | JSX.Element[],
  limit: boolean,
  className?: string,
}) {
  return (
      <div className={className}>
        <div className={limit ? "max-w-[var(--max-website-width)] mx-auto relative" : ""}>
          {children}
        </div>
      </div>
  );
}
