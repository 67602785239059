import React from 'react';

export function MenuButton() {
    return (
        <svg className="cursor-pointer" width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line x1="2" y1="2" x2="22" y2="2" stroke="currentColor" strokeWidth="4" strokeLinecap="round" />
            <line x1="2" y1="10" x2="22" y2="10" stroke="currentColor" strokeWidth="4" strokeLinecap="round" />
            <line x1="2" y1="18" x2="22" y2="18" stroke="currentColor" strokeWidth="4" strokeLinecap="round" />
        </svg>
    );
}
