import React from "react";
import {createContext, useContext, useState} from "react";

type Value = Record<string, any> | null;
type ContextValue = {
  value: Value,
  setValue: (value: Value) => void
}

export const ContextValue = createContext<ContextValue>({ value: null } as ContextValue);
export const useContextValue = () => useContext(ContextValue);

export default function ContextValueProvider({ children }: { children: JSX.Element }) {
  const [value, setValue] = useState<Value>(null);
  return (
      <ContextValue.Provider value={{ value, setValue }}>
        {children}
      </ContextValue.Provider>
  );
}
