import React from "react";

import CreditsSection from "./CreditsSection";
import CreditsName from "./CreditsName";
import CreditsTitle from "./CreditsTitle";

import ATLogo from "./../../assets/logo.svg";
import CloseWhite from "./../../assets/icons/CloseModal.svg";
import GatsbyLogo from "./../../assets/credits/Gatsby.svg";
import TailwindLogo from "./../../assets/credits/Tailwind.svg";
import ReactLogo from "./../../assets/credits/ReactLogo.svg";
import JSLogo from "./../../assets/credits/Javascript.svg";
import CSSLogo from "./../../assets/credits/CSS3.svg";
import TSLogo from "./../../assets/credits/Typescript.svg";
import WebGLLogo from "./../../assets/credits/WebGL.svg";
import LottieLogo from "./../../assets/credits/Lottie.svg";
import { ICredits } from ".";
import CreditsLogos from './CreditsLogos';

type CreditsModalProps = {
  showCredits: boolean,
  pauseAnimation: boolean,
  creditsContainerRef: React.RefObject<HTMLDivElement>,
  creditsContentRef: React.RefObject<HTMLDivElement>,
  onCompleteEvent: () => void,
  setPauseAnimation: React.Dispatch<React.SetStateAction<boolean>>,
  creditsData: ICredits
}

const CreditsModal = ({
  showCredits,
  pauseAnimation,
  creditsContainerRef,
  creditsContentRef,
  onCompleteEvent,
  setPauseAnimation,
  creditsData
}: CreditsModalProps) => {
  return (
    creditsData && (
      <div ref={creditsContainerRef} className={`select-none fixed w-full h-full z-[101]`}>
        <div className={`z-20 absolute w-full flex flex-row justify-center duration-500 ${showCredits ? 'opacity-100' : 'opacity-0'}`}>
          <div className="w-full max-w-[var(--max-website-width)] p-4 md:p-8 flex flex-row justify-end">
            <div
              onClick={() => onCompleteEvent()}
              className="h-[50px] w-[50px] flex justify-center items-center cursor-pointer"
            >
              <CloseWhite />
            </div>
          </div>
        </div>

        <div
          onClick={() => setPauseAnimation(!pauseAnimation)}
          className={`main-container z-10 absolute inset-0 bg-black duration-500 ${showCredits ? 'opacity-100' : 'opacity-0'}`}
        >
          <div className="credits relative w-full">
            <div className="z-0 absolute inset-0 flex flex-row justify-center text-white">
              <div className="max-w-[var(--max-website-width)] w-full h-full flex flew-row items-center justify-center">
                <div className="w-full p-4" ref={ creditsContentRef }>
                  <div className="[&>svg>g>path]:fill-white p-12 flex flex-row justify-center">
                    <ATLogo />
                  </div>

                  <div className="w-full flex flex-col items-center pb-12">
                    {
                      creditsData.people.map((data, index) => {
                        return (
                          <CreditsSection 
                            key={ `people-credits-section-${index}` }
                            data={ data } 
                          />
                        )
                      })
                    }
                  </div>

                  {
                    creditsData.gratitude.map((item, index) => {
                      return (
                        <div className="flex flex-col items-center pb-12 text-center" key={ `gratitude-credits-section-${index}` }>
                          <CreditsTitle>
                            { item.title }
                          </CreditsTitle>
                          <CreditsName>
                            { item.names.join(' · ') }
                          </CreditsName>
                      </div>
                      )
                    })
                  }

                  <CreditsLogos 
                    logos={
                      [
                        <GatsbyLogo />, 
                        <TailwindLogo />,
                        <ReactLogo />
                      ]
                    }
                  />

                  <CreditsLogos 
                    logos={
                      [
                        <JSLogo className="h-[40px] md:h-auto" />, 
                        <CSSLogo className="h-[40px] md:h-auto" />,
                        <TSLogo className="h-[40px] md:h-auto" />
                      ]
                    }
                  />

                  <CreditsLogos 
                    logos={
                      [
                        <WebGLLogo className="h-[30px] md:h-auto" />,
                        <LottieLogo className="h-[30px] md:h-auto" />
                      ]
                    }
                  />

                  <div className="flex flex-col items-center py-12 text-center">
                    <CreditsTitle>
                      No animals were harmed in the making of this website.
                    </CreditsTitle>
                    <CreditsTitle>
                      © 2024 AgileThought. All Rights Reserved.
                    </CreditsTitle>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  )
}

export default CreditsModal;