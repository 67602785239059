import React from 'react';
import { SocialMediaIcons } from '../../SocialMediaIcons/SocialMediaIcons';

export function FooterLogos() {
    return (
        <div className="flex justify-between p-0 flex-col md:flex-row content-evenly md:pb-4">
            <div className="flex justify-center md:justify-items-start p-4 md:p-0">
                <img className="inline" src={require('../../../icons/IconLogo.svg').default} alt="at logo" />
            </div>
            <SocialMediaIcons />
        </div>
    )
}
