import React from "react";
import {useState} from "react";
import { FooterLogos } from "./FooterLogos";
import { FooterCopyright } from "./FooterCopyright";
import { FooterLinks } from "./FooterLinks";
import Button, { ButtonTypes } from "../../Button/Button";
import MaskShapeContainer, { MaskType } from "../../MaskShapeContainer/MaskShapeContainer";
import MailModal from "./MailModal";

export function FooterNavigation() {

  const [showModal, setShowModal] = useState(false);

  function handleClose() {
    setShowModal(false)
  }

  function handleOpen() {
    setShowModal(true)
  }

  return (
    <div className="flex justify-center overflow-hidden -mt-8 md:-mt-20 z-10 relative">
      <MaskShapeContainer maskType={MaskType.TopBulge} className="bg-[#24135F] w-full items-center justify-center flex  px-[2rem] md:px-[5rem]">
        <div className="flex flex-col items-left md:justify-center w-full gap-y-2">
          <div className="self-center">
            <Button onClick={handleOpen} title="Ready To Talk?" buttonType={ButtonTypes.Principal} className="self-center mt-[3rem]" />
          </div>
          <div className="my-4 max-w-[100vw]">
            <FooterLinks />
          </div>
          <FooterLogos />
          <div className="invisible md:visible border-2 border-solid border-[#FFFFFF25]"></div>
          <FooterCopyright onOpenContactUs={handleOpen} />
        </div>
      </MaskShapeContainer>
      <MailModal showContainer={showModal} onClose={handleClose}/>
    </div>
  );
}
