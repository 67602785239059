import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { Link } from "gatsby";
import { Link as LinkType } from "../Navbar/useNavigation";
import { AtLogo } from "../Navbar/AtLogo";
import IsoShape from "../../../assets/icons/iso.svg";
import "./MainMenu.css";
import CloseButtonAnimation from "./CloseButtonAnimation";
import ATPortal from "../../ATPortal/ATPortal";

interface MainMenuProps {
  links: LinkType[],
  onClose: () => void,
  open: boolean
}

const MainMenu: React.FC<MainMenuProps> = ({ links, onClose, open = false }) => {
  const [willClose, setWillClose] = useState(false);
  const navRef = useRef<HTMLUListElement>(null);

  const handleClose: React.MouseEventHandler<HTMLAnchorElement | HTMLButtonElement> = () => {
    setWillClose(true);
  }

  useEffect(() => {
    let timer: string | number | NodeJS.Timeout | undefined;
    if (willClose) {
      timer = setTimeout(() => {
        setWillClose(false);
        onClose();
      }, 800);
    }

    return () => clearTimeout(timer);
  }, [willClose]);

  return open ? (
      <ATPortal>
        <div className={`MainMenu bg-at-vibrant-green-gradient ${willClose ? "close" : ""}`}>    
          <div className="relative max-w-[var(--max-website-width)] mx-auto">
            <div className="flex flex-row flex-nowrap justify-between pl-8 pr-4 py-4 md:pl-10 md:pr-8 md:py-8">
              <div className="MainMenu__Logo z-50"><AtLogo /></div>
              <button onClick={handleClose} className="w-[50px] h-[50px] z-50">
                <CloseButtonAnimation show={!willClose} />
              </button>
            </div>
            <ul className={"MainMenu__Nav"} ref={navRef}>
              {links.map((link, i) => (
                  <li key={link.id} style={{ "--item-order": (i + 1).toString() } as React.CSSProperties}>
                    <Link to={link.path} onClick={handleClose}>
                      <h2 className="sm:pb-4 text-white menu-item font-ztGraftonVariable">
                        {link.text}
                      </h2>
                    </Link>
                  </li>
              ))}
            </ul>
          </div>
          <div className="MainMenu__Iso"><IsoShape /></div>
        </div>
      </ATPortal>
  ) : <></>;
}
export default MainMenu;
