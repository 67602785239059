import React from "react";
import LogoAnimationProvider from "./useLogoAnimation";
import BurgerMenuAnimationProvider from "./useBurgerAnimation";

interface INavbarAnimationsProvider {
  children: JSX.Element;
}

const NavbarAnimationsProvider = ({ children }: INavbarAnimationsProvider) => {
  return (
    <LogoAnimationProvider>
      <BurgerMenuAnimationProvider>{children}</BurgerMenuAnimationProvider>
    </LogoAnimationProvider>
  );
};

export default NavbarAnimationsProvider;
