import React from "react";
import Youtube from "../../assets/icons/SocialMedia/Youtube.svg";
import Facebook from "../../assets/icons/SocialMedia/Facebook.svg";
import Instagram from "../../assets/icons/SocialMedia/Instagram.svg";
import Linkedin from "../../assets/icons/SocialMedia/Linkedin.svg";
import Twitter from "../../assets/icons/SocialMedia/Twitter.svg";
import ButtonSocialMedia from "../ButtonSocialMedia/ButtonSocialMedia";
import Mastadon from "../../assets/icons/SocialMedia/Mastadon.svg";
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton } from 'react-share';


export enum SocialMedia {
  Facebook = "Facebook",
  Instagram = "Instagram",
  Twitter = "Twitter",
  Mastadon = "Mastadon",
  Linkedin = "Linkedin",
  Youtube = "Youtube",
}

interface ShareOnSocialMediaProps {
  socialMedia: SocialMedia[];
  url?: string;
  title?: string;
  description?: string;
  bgColor: string;
  iconColor: string;
  onClick?: (smName: SocialMedia) => void;
}

const ShareOnSocialMedia = ({
  socialMedia,
  url = '',
  title = '',
  description = '',
  bgColor,
  iconColor,
  onClick,
}: ShareOnSocialMediaProps): JSX.Element => {
  const socialMediaIcons: Record<string, React.ReactNode> = {
    Facebook: <Facebook fill={iconColor} />,
    Instagram: <Instagram fill={iconColor} />,
    Twitter: <Twitter fill={iconColor} />,
    Linkedin: <Linkedin fill={iconColor} />,
    Youtube: <Youtube fill={iconColor} />,
    Mastadon: <Mastadon fill={iconColor} />,

  };

  return (
    <>
      {socialMedia.map((item, index) => {
        const handleOnClick = (): void => onClick && onClick(item);
        return (
          <ButtonSocialMedia
            key={`socialMedia-button-${index}`}
            socialMediaIcon={
              item === SocialMedia.Facebook ? 
                <FacebookShareButton url={url} quote = {title}>
                  {socialMediaIcons[item]}
                </FacebookShareButton>
              : item === SocialMedia.Twitter ?
                <TwitterShareButton url={url} title={title}>
                  {socialMediaIcons[item]}
                </TwitterShareButton>
              : item === SocialMedia.Linkedin ?
                <LinkedinShareButton url={url} title={title}>
                  {socialMediaIcons[item]}
                </LinkedinShareButton>
              : socialMediaIcons[item]
            }
            bgColor={bgColor}
            onCLick={handleOnClick}
          />
        );
      })}
    </>
  );
};

export default ShareOnSocialMedia;
