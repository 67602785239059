import React from "react";
import { CardRow } from "../../CardRow/CardRow";
import { LinksCard } from "../../LinksCard/LinksCard";
import { Link } from "../../Link/Link";
import useFooterInfo from "./useFooterInfo";
import Section from "../../../interfaces/section";

export function FooterLinks() {
    const links : Section[] = useFooterInfo();

    return (
        <CardRow>
            { links.map((link, i) => (
                <LinksCard key={`footer-link-${i}`} title={link.subtitle ?? ''} url={link.to ?? '#'}>
                    { link.cards.filter((c) => !!c.to).map((sublink, j) => (
                        <Link key={`footer-sublink-${i}-${j}`} style='mt-2 text-[#FFFFFF] opacity-50' title={sublink.subtitle ?? ''} url={sublink.to ?? '#'} />
                    ))}
                </LinksCard>
            ))}
        </CardRow>
    )
};
