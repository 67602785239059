import React, {useRef} from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import animationJson from "../../../assets/lotties/HamburgerMenuIcon.json";
import useIsomorphicLayoutEffect from "../../../lib/useIsomorphicLayoutEffect";

type Props = {
  show?: boolean
}

let timer: string | number | NodeJS.Timeout | undefined;

const CloseButtonAnimation: React.FC<Props> = ({ show }) => {
  const playerRef = useRef<Player>(null);

  const animateFromTo = (from: number, to: number) => {
    if (from > to) return;
    let nextFrame = from + 1;
    playerRef.current?.setSeeker(nextFrame, true);
    timer = setTimeout(() => {
      animateFromTo(nextFrame, to);
    }, 8);
  }

  useIsomorphicLayoutEffect(() => {
    if (show) animateFromTo(0, 50);
    else animateFromTo(50, 100);
    return () => clearTimeout(timer);
  }, [show]);

  return (
    <Player
        direction={1}
        src={animationJson}
        ref={playerRef}
        renderer={"svg"}
        style={{width: 50}}
    />
  );
}

export default CloseButtonAnimation;
