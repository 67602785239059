import React, {useCallback, useEffect} from "react";
import {createContext, useContext, useState} from "react";

type AnimationContextType = {
  show: boolean,
  fadeIn: () => void,
  fadeOut: () => void
};

export const LogoAnimationContext = createContext<AnimationContextType>({} as AnimationContextType);
export const useLogoAnimation = () => useContext(LogoAnimationContext);

export default function LogoAnimationProvider({children}: {children: JSX.Element}) {
  const [show, setShow] = useState(true);

  const fadeIn = useCallback(() => setShow(true), []);

  const fadeOut = useCallback(() => setShow(false), []);

  return (
      <LogoAnimationContext.Provider value={{ show, fadeIn, fadeOut }}>
        {children}
      </LogoAnimationContext.Provider>
  );
}
