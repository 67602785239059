import React from "react";
import { FC } from "react";
import {createPortal} from "react-dom";

interface Props {
  children: JSX.Element | JSX.Element[]
}

const ATPortal: FC<Props> = ({ children }) => {
  if (typeof document === "undefined") return null;
  let mount = document.getElementById('at-portal');
  return createPortal(children, mount!);
}

export default ATPortal;
