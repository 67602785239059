import React, { ReactNode } from "react";

type CreditsLogosProps = {
  logos: ReactNode[]
}

const CreditsLogos = ({ logos }: CreditsLogosProps) => {
  return (
    <div style={{ "--cols": logos.length } as React.CSSProperties}
         className={ `w-full grid grid-cols-[repeat(var(--cols),minmax(0,1fr))] py-8 px-4 lg:px-0 gap-12 md:gap-4` }>
      {
        logos.map((logo, index) => {
          return (
            <div className="flex flex-row justify-center items-center" key={`logo-${index}`}>
              {logo}
            </div>
          )
        })
      }
    </div>
  ) 
}

export default CreditsLogos;