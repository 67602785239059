import React from "react";

type Props = {
    text?: string;
};

export function Divider({ text }: Props) {
    return (
        <div className='text-[#FFFFFF80] pr-4'>
            {text}
        </div>
    );
};